<template>
  <canvas ref="canvas" style="min-height: 218px !important; height: 100% !important;"></canvas>
</template>

<script>
import { Bar } from 'vue-chartjs'

Chart.defaults.global.defaultFontFamily = "'Montserrat'"
Chart.defaults.global.defaultFontSize = 11
Chart.defaults.global.borderRadius = 5

export default {
  name: 'IndicationChart',
  extends: Bar,
  props: {
    chartData: {
      required: true
    }
  },
  data() {
    return {
      sellers_sales: []
    }
  },
  watch: {
    sellers_sales() {
      this.mountedChart()
    },
  },
  methods: {
    mountedChart() {
      this.renderChart(
        {
          labels: this.chartData.labels,
          datasets: [
            {
              label: this.$t('seller.indication.text_2880'),
              backgroundColor: ["#006B63", "#006B63", "#006B63", "#006B63", "#006B63", "#006B63"],
              borderColor: "transparent",
              borderWidth: 6,
              borderRadius: 5,
              data: this.chartData.data
            }
          ]
        },
        {
          legend: {
            display: false,
            position: "top"
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex]
                dataset.data.reduce((previousValue, currentValue) => {
                  return previousValue + currentValue
                })
                const currentValue = dataset.data[tooltipItem.index]
                return currentValue.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                })
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                borderRadius: 5
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false,
                  min: 0,
                  stepSize: 2,
                  callback: function (value, index, values) {
                    return value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }
                },
                borderRadius: 5
              }
            ]
          }
        }
      )
    }
  },
  mounted() {
    this.mountedChart()
  }
}
</script>
